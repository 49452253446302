import axios from '@/plugins/axios'
export default {
    data() {
        return {
            tKey: 'establishment.',
            loading: false,   
            currentEstablishment: null,       
        }
    },
    methods: {
        async getEstablishment(id) {
            this.loading = true
            await axios.post('api/AHV/getOneByContract', {
                contract: id,
                fullData: true,
            }).then((res) => {
                this.currentEstablishment = res.data
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                this.loading = false
            })
        },
        async getMemberEstablishment(idMember) {
            this.loading = true
            await axios.post('api/AHV/getMemberById', {
                ahvId: idMember,
                fullData: true,
            }).then((res) => {
                this.currentEstablishment = res.data
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                this.loading = false
            })
        }
    },
}